import gql from "graphql-tag";

export const GET_MANAGEMENT_CONNECTIONS = gql`
  query GET_MANAGEMENT_CONNECTIONS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $online: Boolean,
    $lastSeenFrom: DateTime,
		$lastSeenTo: DateTime,
		$authorizationStatus: [AuthorizationStatus],
		$vlanAccessPolicy: [VLANAccessPolicy],
    $vlanSearch: ShortString,
		$authenticationStatus: [AuthenticationStatus],
		$badConnection: Boolean,
		$category: [HostCategoryType],
    $orderBy: ManagementConnectionOrderBy,
    $direction: Direction,

    $organizationSlug: ShortString,
    $macAddress: String,
    $deviceId: String,
    $vlanId: UUID,
    $networkId: UUID,
    $hostId: UUID,
		$hostTypes: [HostTypeEnum!]
		$ssidIds: [UUID!]
    $medias: [ConnectionMediaType!],

		$askForPagination: Boolean!,
  ){
  	management{
	    connections(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
	    	search: $search,
        online: $online,
        lastSeenFrom: $lastSeenFrom,
				lastSeenTo: $lastSeenTo,
				authorizationStatus: $authorizationStatus,
				vlanAccessPolicy: $vlanAccessPolicy,
        vlanSearch: $vlanSearch,
				authenticationStatus: $authenticationStatus,
				category: $category,
        orderBy: $orderBy,
        direction: $direction,
        badConnection: $badConnection,
        organizationSlug: $organizationSlug,
				macAddress: $macAddress,
				deviceId: $deviceId,
				vlanId: $vlanId,
				networkId: $networkId,
				hostId: $hostId,
				hostTypes: $hostTypes,
				ssidIds: $ssidIds,
        medias: $medias,
	    ){
	      pageInfo @include(if: $askForPagination){
	        count
	        itemsPerPage
	        numPages
	      }
	      results {
	        id
	        authorizationStatus
	        device{
	        	id
	        	deviceId
	        	location
	        }
	        host {
	        	category,
	        	provider,
	        	deviceType,
	        	usage,
	        	macAddr,
	        	displayName,
	        	id
			      authFailures{
			        count
			      }
	        }
	        isOnline
	        context {
            deviceLocation
            deviceId
	          isBlocked
	        	portLabel
	          vlanAccessStatus
	          vlanAccessPolicy
	          vlanType
	          vlanName
	          ip
	          hostType
	        }
	        startTime
	        endTime
	        uploadKilobytes
	        downloadKilobytes
	        authorizationStatus
	        authenticationStatus
	        trustedPairingStatus
	        trustedPairingDetails
	        badConnection
	        media
	        band
	        ssid {
	          name
	        }
	        vlan{
	        	id
	        	vid
	        	name
	        	management
	        }
	        network {
	        	id
	          location
	          organization {
	          	id
	          	slug
	            name
	          }
	        }
	      }
	    }
  	}
  }`;
