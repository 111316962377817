import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import { Query } from '@apollo/client/react/components';
import { NetworkError } from 'components/NetworkError';

import { replaceOnQuery } from 'tools/getUrls.js';

import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

import {
  GET_MANAGEMENT_ORGANIZATIONS_LIGHT,
  GET_MANAGEMENT_ORGANIZATIONS,
  GET_MANAGEMENT_ORGANIZATION,
  GET_MANAGEMENT_ORGANIZATION_NETWORKS,
  GET_MANAGEMENT_ORGANIZATION_STATS,
  GET_MANAGEMENT_ORGANIZATION_DEVICES,
} from "./OrganizationsQueries";

import {
  GET_MANAGEMENT_INCIDENTS,
  GET_MANAGEMENT_INCIDENT,
} from "./IncidentsQueries";

import {
  GET_MANAGEMENT_PROJECTS,
  GET_MANAGEMENT_PROJECT,
} from "./ProjectsQueries";

import {
  GET_MANAGEMENT_DEVICES,
  GET_MANAGEMENT_DEVICE,
  GET_MANAGEMENT_DEVICES_HISTORY,
  GET_MANAGEMENT_REGISTRATION_TOKENS,
  GET_MANAGEMENT_DEVICE_RADIOS,
  GET_MANAGEMENT_DEVICE_SSIDS,
  GET_MANAGEMENT_DEVICE_DISKS,
  GET_MANAGEMENT_DEVICE_PORTS,
  GET_MANAGEMENT_DEVICE_STATS,
} from "./DevicesQueries";

import {
  GET_MANAGEMENT_HOSTS,
  GET_MANAGEMENT_HOST,
  GET_MANAGEMENT_HOST_STATS,
} from "./HostsQueries";

import {
  GET_MANAGEMENT_CONNECTIONS,
} from "./ConnectionsQueries";

import {
  GET_MANAGEMENT_VOLUMES,
} from "./VolumesQueries";

import {
  GET_MANAGEMENT_SSID,
  GET_MANAGEMENT_SSIDS,
} from "./SsidsQueries";

import {
  GET_MANAGEMENT_NETWORKS,
  GET_MANAGEMENT_NETWORK,
  GET_MANAGEMENT_NETWORK_VLAN,
  GET_MANAGEMENT_NETWORK_VLANS,
  GET_MANAGEMENT_NETWORK_VLANSONLY,
  GET_MANAGEMENT_NETWORK_MICROVLANS,
  GET_MANAGEMENT_NETWORK_STATSHOSTS,
  GET_MANAGEMENT_NETWORK_SSIDS,
  GET_MANAGEMENT_NETWORK_STATS,
  GET_MANAGEMENT_NETWORKS_PLACES,
} from "./NetworksQueries";

import {
  GET_MANAGEMENT_TASK,
  GET_MANAGEMENT_TASKS,
} from "./TasksQueries";

import {
  GET_PREFERENCES,
} from "./Preferences/PreferencesQueries";

import {
  GET_MANAGEMENT_MONITORINGEVENTS,
} from "./MonitoringEventsQueries";

import {
  GET_MANAGEMENT_ROGUEWIFIS,
} from "./RogueWifisQueries";

import {
  GET_PACKET_LOGS,
  GET_IPADDRESS_STATS
} from "./PacketLogsQueries";

import {
  GET_MANAGEMENT_PORTSCANS,
} from "./PortScansQueries";

import {
  GET_STATS,
  GET_STATS_INCIDENTS,
} from "./StatsQueries";

import {
  GET_MANAGEMENT_USER,
  GET_MANAGEMENT_USERS,
  GET_MANAGEMENT_USERHISTORY,
} from "./UsersQueries";

import {
  GET_MANAGEMENT_WIFISURVEYS,
  GET_MANAGEMENT_WIFISURVEY,
} from "./WifiSurveysQueries";

import {
  GET_MANAGEMENT_SERVICE,
  GET_MANAGEMENT_SERVICES,
  GET_MANAGEMENT_PODS,
  GET_MANAGEMENT_POD,
} from "./ServicesQueries";

import {
  GET_MANAGEMENT_PACKETCAPTURES,
  GET_MANAGEMENT_PACKETCAPTURE,
} from "./PacketCapturesQueries";

import {
  GET_MANAGEMENT_DNS_HOST,
  GET_MANAGEMENT_DNS_DOMAIN,
  GET_MANAGEMENT_DNS_REQUEST,
} from "./DnsQueries";


import {
  GET_GALLERY,
  GET_GALLERY_TAGS
} from "./GalleryQueries"

import { withTabActive } from 'components/WithTabActive';
import { ErrorExpandable } from 'components/ErrorExpandable';
//import { ErrorOffline } from 'components/ErrorOffline';

class ManagementControllerBase extends Component {
  static defaultProps = {
    operation: 'organizations',
    notifyOnNetworkStatusChange: true,
    dynamicVariables: false,
    showNetworkError: false,
    onError: () => {},
  }

  constructor(props){
    super(props);

    this.state = {
      variablesChanged: false,
      prevVariables: props.variables,
      _error: null,
    }
  }

  static getDerivedStateFromProps(props, state){
    let nextState = Object.assign({}, state);
    nextState.variablesChanged = !_.isEqual(state.prevVariables, props.variables);
    nextState.prevVariables = props.variables;
    return nextState;
  } 

  render() {
    const {
      operation,
      dynamicVariables,
      showNetworkError,
      history,
    } = this.props;

    const {
      _error
    } = this.state;

    let selfUserId = _.get(this.props,"variables.selfUserId");

    if(!selfUserId) selfUserId = _.get(window,"keycloak.tokenParsed.sub");

    let operationMutation = "";
    let variables = this.props.variables;
    //console.log(typeof _.get(variables,"askForPagination"))
        variables = Object.assign({askForTokens: false}, variables, {
          askForPagination: typeof _.get(variables,"askForPagination") === 'boolean' ? variables.askForPagination : true,
          askForAuthorizations: typeof _.get(variables,"askForAuthorizations") === 'boolean' ? variables.askForAuthorizations : false,
        });

    let pollInterval = 0;
    let fetchPolicy = "network-only";
    let operationObjectSelector = operation;
    /*eslint no-fallthrough: "off"*/
    switch(operation){
      case "customQuery":
        operationMutation = _.get(this.props,"query");
        break;
      case "preferences":
        operationMutation = GET_PREFERENCES;
        break;
      case "task":
        operationMutation = GET_MANAGEMENT_TASK;
        break;
      case "tasks":
        operationMutation = GET_MANAGEMENT_TASKS;
        break;
      case "incident":
        operationMutation = GET_MANAGEMENT_INCIDENT;
        break;
      case "incidents":
        operationMutation = GET_MANAGEMENT_INCIDENTS;
        break;
      case "gallery":
        operationMutation = GET_GALLERY;
        break;
      case "galleryTags":
        operationMutation = GET_GALLERY_TAGS;
        break;
      case "network":
        operationMutation = GET_MANAGEMENT_NETWORK;
        break;
      case "networkVlan":
        operationMutation = GET_MANAGEMENT_NETWORK_VLAN;
        break;
      case "networkVlans":
        operationMutation = GET_MANAGEMENT_NETWORK_VLANS;
        break;
      case "networkVlansOnly":
        operationMutation = GET_MANAGEMENT_NETWORK_VLANSONLY;
        break;
      case "networkMicroVlans":
        operationMutation = GET_MANAGEMENT_NETWORK_MICROVLANS;
        break;
      case "networkStatsHosts":
        operationMutation = GET_MANAGEMENT_NETWORK_STATSHOSTS;
        break;
      case "networkSsids":
        operationMutation = GET_MANAGEMENT_NETWORK_SSIDS;
        break;
      case "networkStats":
        operationMutation = GET_MANAGEMENT_NETWORK_STATS;
        break;
      case "networkPlaces":
        operationMutation = GET_MANAGEMENT_NETWORKS_PLACES;
        break;
      case "networks":
        operationMutation = GET_MANAGEMENT_NETWORKS;
        break;
      case "packetCapture":
        operationMutation = GET_MANAGEMENT_PACKETCAPTURE;
        break;
      case "packetCaptures":
        operationMutation = GET_MANAGEMENT_PACKETCAPTURES;
        break;
      case "host":
        operationMutation = GET_MANAGEMENT_HOST;
        break;
      case "hostStats":
        operationMutation = GET_MANAGEMENT_HOST_STATS;
        break;
      case "hosts":
        operationMutation = GET_MANAGEMENT_HOSTS;
        break;
      case "monitoringEvents":
        operationMutation = GET_MANAGEMENT_MONITORINGEVENTS;
        break;
      case "rogueWifis":
        operationMutation = GET_MANAGEMENT_ROGUEWIFIS;
        operationObjectSelector = "rogueSsids"
        break;
      case "packetLogs":
        operationMutation = GET_PACKET_LOGS;
        break;
      case "ipAddressStats":
        operationMutation = GET_IPADDRESS_STATS;
        break;
      case "portScans":
        operationMutation = GET_MANAGEMENT_PORTSCANS;
        break;
      case "stats":
        operationMutation = GET_STATS;
        break;
      case "statsIncidents":
        operationMutation = GET_STATS_INCIDENTS;
        break;
      case "connections":
        operationMutation = GET_MANAGEMENT_CONNECTIONS;
        break;
      case "volumes":
        operationMutation = GET_MANAGEMENT_VOLUMES;
        break;
      case "project":
        operationMutation = GET_MANAGEMENT_PROJECT;
        break;
      case "projects":
        operationMutation = GET_MANAGEMENT_PROJECTS;
        break;
      case "device":
        operationMutation = GET_MANAGEMENT_DEVICE;
        variables = Object.assign({askForDisks: false, askForPorts: false, askForPods: false, askForVlans: false, excludeConfigPresets: true}, variables);
        break;
      case "deviceRadios":
        operationMutation = GET_MANAGEMENT_DEVICE_RADIOS;
        break;
      case "deviceSsids":
        operationMutation = GET_MANAGEMENT_DEVICE_SSIDS;
        break;
      case "devicePorts":
        operationMutation = GET_MANAGEMENT_DEVICE_PORTS;
        break;
      case "deviceStats":
        operationMutation = GET_MANAGEMENT_DEVICE_STATS;
        break;
      case "deviceDisks":
        operationMutation = GET_MANAGEMENT_DEVICE_DISKS;
        break;
      case "devicesInventory":
        variables = Object.assign({}, variables, {askForTokens: true});
      case "devices":
        operationMutation = GET_MANAGEMENT_DEVICES;
        break;
      case "devicesHistory":
        operationMutation = GET_MANAGEMENT_DEVICES_HISTORY;
        break;
      case "registrationTokens":
        operationMutation = GET_MANAGEMENT_REGISTRATION_TOKENS;
        break;
      case "organization":
        operationMutation = GET_MANAGEMENT_ORGANIZATION;
        break;
      case "organizationStats":
        operationMutation = GET_MANAGEMENT_ORGANIZATION_STATS;
        break;
      case "organizationDevices":
        operationMutation = GET_MANAGEMENT_ORGANIZATION_DEVICES;
        break;
      case "organization-networks":
        operationMutation = GET_MANAGEMENT_ORGANIZATION_NETWORKS;
        break;
      case "organizationsLight":
        operationMutation = GET_MANAGEMENT_ORGANIZATIONS_LIGHT;
        break;
      case "service": 
        operationMutation = GET_MANAGEMENT_SERVICE;
        break;
      case "services": 
        operationMutation = GET_MANAGEMENT_SERVICES;
        operationObjectSelector = "recipes"
        break;
      case "pod": 
        operationMutation = GET_MANAGEMENT_POD;
        break;
      case "ssid": 
        operationMutation = GET_MANAGEMENT_SSID;
        break;
      case "ssids": 
        operationMutation = GET_MANAGEMENT_SSIDS;
        break;
      case "pods":
        operationMutation = GET_MANAGEMENT_PODS;
        break;
      case "dnsHost": 
        operationMutation = GET_MANAGEMENT_DNS_HOST;
        break;
      case "dnsDomain": 
        operationMutation = GET_MANAGEMENT_DNS_DOMAIN;
        break;
      case "dnsRequest": 
        operationMutation = GET_MANAGEMENT_DNS_REQUEST;
        break;
      case "users": 
        operationMutation = GET_MANAGEMENT_USERS;
        break;
      case "userChanges": 
        operationMutation = GET_MANAGEMENT_USERHISTORY;
        break;
      case "user": 
        operationMutation = GET_MANAGEMENT_USER;
        break;
      case "wifiSurveys":
        operationMutation = GET_MANAGEMENT_WIFISURVEYS;
        break; 
      case "wifiSurvey":
        operationMutation = GET_MANAGEMENT_WIFISURVEY;
        break;        
      case "organizations":
      default:
        operationMutation = GET_MANAGEMENT_ORGANIZATIONS;
    }

    if(_.get(this.props,"variables.pollInterval") === 0 || this.props.isTabHidden){
      pollInterval = 0;
    } else {
      pollInterval = _.get(this.props,"variables.pollInterval") || pollInterval
    }

    let computedVariables = {};
    if(dynamicVariables){
      for(var key in variables){
        if(typeof variables[key] === "function"){
          computedVariables[key] = variables[key]();
        }
      }
    }

    return (
      <Query
        query={operationMutation}
        variables={Object.assign(variables, computedVariables)}
        pollInterval={pollInterval}
        fetchPolicy={_.get(this.props,"variables.fetchPolicy") || fetchPolicy}
        errorPolicy={"all"}
        notifyOnNetworkStatusChange={this.props.notifyOnNetworkStatusChange}
        onError={(error) => {
          this.setState({
            _error: error,
          }, () => {typeof this.props.onError === "function" && this.props.onError(error)})
        }}
        onCompleted={(data) => {
          
          this.setState({
            _error: _.get(data,"error") === undefined ? null : _.get(data,"error")
          }, () => {if(typeof this.props.onCompleted === "function") this.props.onCompleted(data)})
        }}
        skip={this.props.skip || false}
      >
        {({ loading, error, data, networkStatus, refetch, called, updateQuery, stopPolling, startPolling }) => {

          // Try to avoid problem for very slow queries
          if(loading && pollInterval > 0){
            stopPolling();
          } else if (pollInterval > 0){
            startPolling(pollInterval)
          }

          if(_.get(data,`management.[${operationObjectSelector}].pageInfo.numPages`) && _.get(data,`management.[${operationObjectSelector}].pageInfo.numPages`) < +_.get(variables,"page")){
            console.log("change page")

            history.push(window.location.pathname + replaceOnQuery(window.location.search, {page:_.get(data,`management.[${operationObjectSelector}].pageInfo.numPages`)}))  
          }

          const networkErrorComponent = (options = {}) => { return (
            <NetworkError error={ _error || error} size={_.get(options, "size") || null} light={_.get(options, "light")}/>
          )}

          if (error && !_.get(error,"networkError")) return <ErrorExpandable error={error} content={error} />;
          if (showNetworkError && _.get(error,"networkError")) {
            this.props.alert.show(
              <FormattedHTMLMessageFragment
                id={"generic.errors.networkErrorOccured.title"}
              />, {
              type: 'error',
            })
          }

          return (
            <Fragment key={operationMutation}>
              {this.props.render({...this.props, ...data, loading, refetch, error : error === undefined ? null : error, networkErrorComponent})}
            </Fragment>
          );
        }}
      </Query>
    );
  }

}

export const ManagementController = withAlert()(withRouter(withTabActive(ManagementControllerBase)));
