import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

import LogoFullBlack from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoFullBlack.svg';
import LogoFullWhite from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoFullWhite.svg';
import LogoFullBlackLight from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoFullBlackLight.svg';
import LogoFullWhiteLight from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoFullWhiteLight.svg';
import LogoPlainBlack from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoPlainBlack.svg';
import LogoPlainWhite from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoPlainWhite.svg';
import LogoTransparentFullBlack from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoTransparentFullBlack.svg';
import LogoTransparentFullWhite from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoTransparentFullWhite.svg';
import LogoTransparentBlack from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoTransparentBlack.svg';
import LogoTransparentWhite from 'styles/semantic/dist/themes/sentinelC/assets/images/LogoTransparentWhite.svg';

const DEFAULT_PROPS = {
  full: false,
  inverse: false,
  transparent: false,
}

class LogoBase extends Component {

  _getIcon = () => {
    const {
      full,
      inverse,
      light,
      transparent,
    } = this.props;

    if (full) {
      return light ?
        inverse ? LogoFullWhiteLight : LogoFullBlackLight
        :
        transparent ?
          inverse ? LogoTransparentFullWhite : LogoTransparentFullBlack
          :
          inverse ? LogoFullWhite : LogoFullBlack
      ;
    }

    if(transparent) {
      return inverse ? LogoTransparentWhite : LogoTransparentBlack;
    }

    return inverse ? LogoPlainWhite : LogoPlainBlack;

  }
  render() {
    const {
      className,
      full,
      inverse,
      light,
      transparent,
      ...rest
    } = this.props;

    return (
      <Image className={['logo', full ? ' full' : '', inverse ? ' inverse' : '', transparent ? ' transparent' : '', className].join(' ')} alt={this.props.alt || '' + transparent ? ' transparent' : ''} {...rest} src={this._getIcon()}/>
    );
  }
}

export default function Logo(props){
  const componentProps = Object.assign(DEFAULT_PROPS, props);
  return <LogoBase {...componentProps} />
}